import React, { useEffect, useState } from 'react'
import { Container, Grid, Button, Box, Typography, Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Autocomplete, } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { get_nft, get_rewards_details } from '../Api/Api';
import { useParams } from 'react-router-dom';

export default function useCustomHook() {
    const [data , setData] = useState([]);
  const { rewardId } = useParams();

    useEffect(()=>{
      if (rewardId){
        const sendData = new FormData()
        sendData.append('rewardId', rewardId);
        axios({
          method: 'POST',
          url: get_rewards_details,
          data:sendData
        }).then(res => {
          if (res.data.status === 200) {
            setData(res.data.data)
          } else {
            setData([])
          }
        }).catch(err => {
          alert('Oops something went wrong ' + err)
        });
      }
      },[])


  return data;
}
