import React, { useState } from 'react'
import {  Navigate, useRoutes } from 'react-router-dom'
import CreateNFTPage from './Pages/CreateNFTPage';



// this is pages

// this variable is for starting url
//this variable is for nadi local development 
// export const startUrl = `/nadipms/`;

//this variable is for local development 
export const startUrl = `/`;

// this variable for testing server
// export const startUrl = `/nadi/testing/`;

// this variable is for live server
// export const startUrl = `/nadi/web/`;

function Routes() {

    return useRoutes([
      {
        path: '/:rewardId',
        element: <CreateNFTPage />,
      },
    ])
}

export default Routes;