
// UAT
// const apibaseUrl = `https://mdqualityapps.in/API/pedal2earn/UAT/`;

// Live
const apibaseUrl = `https://pedal2earn.com/api/`;

const methodGet = 'GET';
const methodPost = 'POST';


const get_nft = `${apibaseUrl}get_nft`;

const get_rewards_details = `${apibaseUrl}get_rewards_details`;

const update_reward_nft_address = `${apibaseUrl}update_reward_nft_address`;

export { methodGet, methodPost, apibaseUrl, get_nft, get_rewards_details, update_reward_nft_address }