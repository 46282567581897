import React, { useEffect, useState } from 'react'
import { Container, Grid, Button, Box, Typography, Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Autocomplete, } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { useWallet } from '@solana/wallet-adapter-react';
import {
    clusterApiUrl,
    Keypair,
    PublicKey,
    SystemProgram,
    Transaction,
    Connection
} from '@solana/web3.js';
import { decode } from 'bs58';
import { useNavigate, useParams } from 'react-router-dom';
import { encodeBase64, inAppWalletAdapter, useConnection } from '../Solana.utils';
import { get_nft, update_reward_nft_address } from '../Api/Api';
import SnackBar from '../components/SnackBar';
import useCustomHook from '../components/useCustomHook';


export default function CreateNFTPage() {


 const navigate = useNavigate();
 const [publicKey, setPublicKey] = useState(null);
 const [WalletConnected , setWalletConnected] = useState(true);
 const { connect, disconnect, connected } = useWallet();
 const connection = useConnection();
 const [keypair, setKeypair] = useState(null);
  const { rewardId } = useParams();
 const [ShowText , setShowText] = useState(null);
 const data  = useCustomHook();
 const [convertedFileBase, setConvertedFileBase] = useState(null);
 const [open, setOpen] = useState(false);
 const [status, setStatus] = useState(false);
 const [color, setColor] = useState(false);
 const [message, setMessage] = useState("");

 const [SuccessUI , setShowSuccessUI] = useState(false);

  const handleApiKey = (apiKeyString) => {
  const publicKey = new PublicKey(apiKeyString);
  setPublicKey(publicKey);
  };




  function base64ToFile(base64String, fileName) {
    const chunks = base64String.match(/.{1,1024}/g);
      
    let decodedString = '';
    for (let i = 0; i < chunks.length; i++) {
        decodedString += atob(chunks[i]);
    }
  
    const byteCharacters = decodedString;
  
    const byteArrays = [];
    for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays.push(byteCharacters.charCodeAt(i));
    }
  
    const byteArray = new Uint8Array(byteArrays);
  
    const blob = new Blob([byteArray]);

    const file = new File([blob], fileName);
  
      return new File([blob], fileName);
  }

    const base64String = data?.images;
    const fileName = `${data?.names}.jpg`; 



    
    useEffect(() => {
      if (data?.images !== '' && data?.images !== null && data?.images !== undefined) {
        const convertedFile = base64ToFile(base64String, fileName);
        setConvertedFileBase(convertedFile)
        const apiPublicKey = data?.public_key;
        handleApiKey(apiPublicKey);
      }
    }, [data]);


    const createNFT = async () => {
      try {
          const formData = new FormData();
          formData.append("network", "devnet");
          formData.append("creator_wallet", data?.public_key);
          formData.append("name", data?.names);
          formData.append("symbol", "PTE");
          formData.append("description", data?.descriptions);
          formData.append("image", convertedFileBase , fileName);
          formData.append("royalty", 5);
          formData.append('service_charge', JSON.stringify({
            "receiver":process.env.REACT_APP_RECEIVER,
            "amount": 0.01
          }));
    
          const axiosConfig = {
            headers: {
              "x-api-key": process.env.REACT_APP_APIKEY,
            },
          };
    
          axios.post("https://api.shyft.to/sol/v2/nft/create", formData, axiosConfig)
            .then(async response => {
              const encodedTransaction = response.data.result.encoded_transaction;
              const connection = new Connection(clusterApiUrl("devnet"), 'confirmed');
              const recoveredTransaction = Transaction.from(
                Uint8Array.from(atob(encodedTransaction), c => c.charCodeAt(0))
              );
              const walletAdapter = inAppWalletAdapter(data?.public_key, data?.private_key);
              const signedTx =await walletAdapter.signTransaction(recoveredTransaction);
              const confirmTransaction = await connection.sendRawTransaction(signedTx.serialize());
              return { mint: response.data.result.mint, confirmTransaction };
            })
            .then(({ mint , confirmTransaction }) => {
              if(confirmTransaction){
              CreateDigital(mint)
              }
            })
            .catch((error) => {
              setMessage('Something Went Wrong');
              setOpen(true);
              setStatus(false);
              setColor(false);
              console.error('Error creating NFT:', error);
            });
      } catch (error) {
        console.error('Error in createNFT:', error);
      }
    };
    

    useEffect(() => {
      if (convertedFileBase) {
        createNFT();
      }
    }, [convertedFileBase]);


      const CreateDigital = (mintAddress) => {
        if(mintAddress){
        const sendData = new FormData()
        sendData.append('symbol', "PTE");
        sendData.append('nft_address', mintAddress);
        sendData.append('rewardId', rewardId);
        axios({
          method: 'POST',
          url: update_reward_nft_address,
          data:sendData
        }).then(res => {
          if (!res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(true)
            setColor(true)
            setShowSuccessUI(true);
          } else {
            setOpen(true)
            setStatus(false)
            setColor(false)
            setMessage(res.data.message)
          }
        }).catch(err => {
          alert('Oops something went wrong ' + err)
        });
      }
      else{
          setMessage('Something Went Wrong');
          setOpen(true);
          setStatus(false);
          setColor(false);
      }
      }





      // const generateNewAccount = async (row , index) => {
      //   const newKeypair = Keypair.generate();
      //   const secretKeyBase64 = encodeBase64(newKeypair.secretKey);
      //   console.log(newKeypair.secretKey)
      //   console.log(secretKeyBase64);
      // }

      // useEffect(()=>{
      //   generateNewAccount();
      // },[])



    return (
      <Box sx={{minHeight:'100vh' , display: 'flex', flexDirection:'column', justifyContent: 'center', alignContent:'center' , alignItems:'center'}}> 
          <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
        <Box textAlign='center'>
        {
        SuccessUI ?
        <Typography variant='subtitle1' fontWeight={600}>Your Digital Milestone is Successfully Created</Typography>
        :
        <div id="SqCo">
        <p>
        Designing Your Unique NFT Experience,
        <br />
        Just a Moment! 
        <span className="point one"> .</span>
        <span className="point two">..</span>
        <span className="point three">. </span>
        </p>
        <span className="bloc one"></span>
        <span className="bloc two"></span>
        <span className="bloc three"></span>
        <span className="bloc four"></span>
        <span className="bloc five"></span>
        </div>
        }
        </Box>
      </Box>
    )
}
