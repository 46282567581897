import React, { useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { ContextProvider } from './Context/ContextProvider';
import { clusterApiUrl  , Keypair , PublicKey } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

import {
  ConnectionProvider,
  WalletProvider,
  useWallet,
} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { SlopeWalletAdapter } from "@solana/wallet-adapter-slope";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import {
  SolletWalletAdapter,
  SolletExtensionWalletAdapter,
} from "@solana/wallet-adapter-sollet";
import { inAppWalletAdapter } from './Solana.utils';
import useCustomHook from './components/useCustomHook';

require('@solana/wallet-adapter-react-ui/styles.css');

function App() {

  const network = (process.env.REACT_APP_SOLANA_NETWORK ||
    "devnet");  

  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const data  = useCustomHook();

  const inAppWalletAdapterInstance = useMemo(() => {
    if (data?.wallet_address && data?.private_key) {
      return inAppWalletAdapter(data?.wallet_address, data?.private_key);
    } else {
      return null; 
    }
  }, [data?.wallet_address, data?.private_key]);

  const wallets = useMemo(() => [inAppWalletAdapterInstance].filter(Boolean), [inAppWalletAdapterInstance]);


  let theme = createTheme({
    palette: {
      primary: {
        main: '#09628e'
      },
      secondary: {
        main: '#343434',
      }
    },
  });



  return (
    <ThemeProvider theme={theme}>
    <ConnectionProvider endpoint={endpoint}>
    <WalletProvider wallets={wallets} autoConnect>
    <WalletDialogProvider>
    <Routes />
    </WalletDialogProvider>
    </WalletProvider>
    </ConnectionProvider>
    </ThemeProvider>
  );
}


export default App;
